'use strict'
const prod = process.env.VUE_APP_ENV === 'production'
export const baseUrl = {

    // mainProcurement: 'http://192.168.31.100:8086/procurement',
    mainProcurement: 'http://119.23.51.157:8086/procurement',

    // main: 'http://testxuexiao1.fsytss.com/official' // 本地调试用
    // main: 'https://api.xuexiao.fsytss.com/official',
    // main: 'https://srm.fsytss.com/srm',
    // main: 'http://192.168.31.100:8093/offline/sales',
     main: prod ? 'https://srm.fsytss.com/srm' : 'http://119.23.51.157:8091/',

}
export const loginParam = {
    grantType: 'OPENID',
    clientId: 'customer',
    credential: "",
    secret: '73d1c2341636435f8e364f9db552a255',
    username: ''
}
export const uploadUrl = prod ? 'https:://api.xuexiao.fsytss.com/official/v1/file/upload' : 'http://testxuexiao.fsytss.com/official/v1/file/upload'
export const appId = 'wx1adbac727573ad83';
export const appProd = prod;
