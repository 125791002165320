export const ACCESS_TOKEN = 'Access-Token'
export const ACCESS_CustomerUser = 'ACCESS_CUSTOMER_USER'

export const ENTERING_DATA_LIST = 'ENTERING_DATA_JSON'

export const ACCESS_CLIENT = 'ACCESS_CLIENT'
export const ACCESS_CLIENT_DATA = 'ACCESS_CLIENT_DATA'

export const PURCHASER_ACCESS_TOKEN = 'PURCHASER_ACCESS_TOKEN'
export const PURCHASER_CURRENT = 'PURCHASER_CURRENT'

export const SUPPLIER_ACCESS_TOKEN = 'SUPPLIER_ACCESS_TOKEN'
export const SUPPLIER_CURRENT = 'SUPPLIER_CURRENT'
export const SUPPLIER_ORDER_SEARCH_ADD = 'SUPPLIER_ORDER_SEARCH_ADD'

export const ANTI_COUNTERFEITING_CODE_ACCESS_TOKEN = 'ANTI_COUNTERFEITING_CODE_ACCESS_TOKEN'
export const ANTI_COUNTERFEITING_CODE_OPEN_ID = 'ANTI_COUNTERFEITING_CODE_OPEN_ID'
export const ANTI_COUNTERFEITING_CODE_CODE = 'ANTI_COUNTERFEITING_CODE_CODE'