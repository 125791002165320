import Vue from 'vue'
import axios from 'axios'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import { baseUrl } from '@/config/env.dev'
import { Notify } from 'vant';
import router from "../router";

axios.defaults.headers.post['Content-Type'] = 'application/json'

const service = axios.create({
  baseURL: baseUrl.main,
  method: 'post',
  timeout: 60000
})

// 错误处理
const err = (error) => {
  console.log('error')
  return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use(config => {
  return config
}, err)

// 响应拦截器
service.interceptors.response.use(response => {
  if ((response.data.status && response.data.status !== 0) || response.data.error) {
    handleError(response.data)
    return Promise.reject(response.data)
  }
  return response.data
}, err)

export function post (url, params = {}, withToken = true) {
  if (withToken) {
    url = appendToken(url)
  }
  return new Promise((resolve, reject) => {
    service.post(url, JSON.stringify(params))
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })
}

function appendToken (url) {
  const token = Vue.ls.get(ACCESS_TOKEN)
  // 采购商
  // const token = '1e4260d8fe7340678f5dfee33735ed66';
  // 供应商
  // const token = 'e04f597674654f2489f4e9cb1c6cbad9';
  if (url.indexOf('?') < 0) {
    url += '?access_token=' + token
  } else {
    url += '&access_token=' + token
  }
  return url
}

function handleError (res) {
  if (res.status === 1004) {
    res.message = '会话已过期，请重新登录'
    router.replace({
      path: '/login'
    }).then(r => {});
  }
  // message.error(res.message)
  Notify({ type: 'warning', message: res.message });
}
